// import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./terms.module.scss";

const testimonialScrl = {
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1261: {
      items: 3,
    },
  },
};

const PrivacyPolicy = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };
  return (
    <>
      {/* <Header/> */}
      <div
        className={`${styles.topMainHdr} siteTopNavbar ${
          isMenuOpen ? "show-nav" : ""
        }`}
      >
        <div className="container">
          <div className={styles.hdrRow}>
            <div className={styles.hdrLogo}>
              <a href={"/"}>
                <img src={"../../images/logo.png"} alt="Your Company Logo" title="Comapny Logo" />
              </a>
            </div>
            <div className={styles.hdrMenuPrt}>
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li>
                        <Link
                          to="/#about-sec"
                          onClick={() => handleMenuClick("about-sec")}
                        >
                          {" "}
                          {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#personalCredit"
                          onClick={() => {
                            handleMenuClick("personalCredit");
                          }}
                        >
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/#creditGenius"
                          onClick={() => {
                            handleMenuClick("creditGenius");
                          }}
                        >
                          Credit Genius
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditGames"
                          onClick={() => {
                            handleMenuClick("creditGames");
                          }}
                        >
                          Credit Games
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditSimulator"
                          onClick={() => {
                            handleMenuClick("creditSimulator");
                          }}
                        >
                          Credit Simulator
                        </a>
                      </li>

                      <li>
                        <a
                          href="/#downloadApp"
                          onClick={() => {
                            handleMenuClick("downloadApp");
                          }}
                        >
                          Download App
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#blog"
                          onClick={() => {
                            handleMenuClick("blog");
                          }}
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================ Banner Section Start ================ */}
      <section className={styles.faqBannerSec}>
        <div className="container">
          <div className={styles.bannerRow}>
            <h3 className="mt-5">Terms and Conditions for Credit Genius</h3>
            <p>
              Welcome to Credit Genius, developed by The Great American
              Credit Secret LLC. By using our app, you agree to the following
              terms and conditions. Please read them carefully.
            </p>
            <h4>1. *Acceptance of Terms*:</h4>
            <p>
              By accessing and using Credit Genius, you accept and agree to
              be bound by these terms. If you do not agree to these terms, do
              not use the app.
            </p>
            <h4>2. *Privacy Policy*:</h4>
            <p>
              Your privacy is important to us. Our Privacy Policy explains how
              we collect, use, and protect your personal information. By using
              the app, you consent to such processing and you warrant that all
              data provided by you is accurate.
            </p>
            <h4>3. *User Accounts*:</h4>
            <p>
              You may need to create an account to access certain features of
              the app. You are responsible for maintaining the confidentiality
              of your account and password and for restricting access to your
              device.
            </p>
            <h4>4. *Services Offered*:</h4>
            <p>
              Credit Genius provides AI-driven credit monitoring and
              financial advice. It is not a substitute for professional
              financial advice. You are responsible for verifying the accuracy
              and suitability of all information provided through the app.
            </p>
            <h4>5. *Intellectual Property*:</h4>
            <p>
              All content in the app, including text, graphics, logos, images,
              and software, is the property of The Great American Credit Secret
              LLC or its content suppliers and is protected by international
              copyright laws.
            </p>
            <h4>6. *Use Restrictions*:</h4>
            <p>
              You are prohibited from using the app in any way that could
              damage, disable, overburden, or impair any Credit Genius server,
              or the network(s) connected to any Credit Genius server.
            </p>
            <h4>7. *Modifications to the App*:</h4>
            <p>
              The Great American Credit Secret LLC reserves the right at any
              time and from time to time to modify or discontinue, temporarily
              or permanently, the app (or any part thereof) with or without
              notice.
            </p>
            <h4>8. *Termination*:</h4>
            <p>
              The Great American Credit Secret LLC may terminate your access to
              the app without cause or notice, which may result in the
              forfeiture and destruction of all information associated with your
              account.
            </p>
            <h4>9. *Limitation of Liability*:</h4>
            <p>
              The Great American Credit Secret LLC shall not be liable for any
              direct, indirect, incidental, special, consequential, or exemplary
              damages resulting from your use of the app.
            </p>
            <h4>10. *Indemnification*:</h4>
            <p>
              You agree to indemnify and hold harmless The Great American Credit
              Secret LLC, its officers, directors, employees, and agents from
              any and all claims, damages, expenses, and liability arising from
              your use of the app.
            </p>
            <h4>11. *Governing Law*:</h4>
            <p>
              These terms shall be governed by and construed in accordance with
              the laws of the jurisdiction in which The Great American Credit
              Secret LLC is registered, without giving effect to any principles
              of conflicts of law.
            </p>
            <h4>12. *Changes to Terms*:</h4>
            <p>
              The Great American Credit Secret LLC reserves the right to update
              or change these terms at any time. Your continued use of the app
              after any such changes constitutes your acceptance of the new
              terms.
            </p>
            <p className="mt-3">
              By using Credit Genius, you acknowledge that you have read
              these terms and agree to be bound by them.
            </p>
          </div>
        </div>
      </section>
      {/* ================ Banner Section End ================ */}

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
