import { signInWithPopup } from "firebase/auth";
import Header from "../../shared/header/header";
import styles from "./login.module.scss";
import { appleProvider, auth, googleProvider } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/reducers/auth";
import { useNavigate } from "react-router-dom";
import { getFirstAndLastName } from "../../../utils/common";
import constants from "../../../utils/constants";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth?.data?.userDetails);
  // Google login function
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const payload = {
        providerName: "google",
        fcmToken: result?.user?.accessToken,
        emailAddress: result?.user?.email,
        firstName:
          getFirstAndLastName(result?.user?.displayName)?.firstName || "",
        lastName:
          getFirstAndLastName(result?.user?.displayName)?.lastName || "",
      };
      dispatch(
        login({
          payload,
          isTestEnv: constants.IS_TEST_ARRAY_URL,
        })
      ).then(() => {
        navigate("/dashboard");
      });
    } catch (error) {
      console.error("Google login error: ", error);
    }
  };

  // Apple login function
  const handleAppleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const payload = {
        providerName: "apple",
        fcmToken: result?.user?.accessToken,
        emailAddress: result?.user?.email,
        firstName:
          getFirstAndLastName(
            result?.user?.displayName ??
              userDetails?.firstName ??
              '—'
          )?.firstName || "",
        lastName:
          getFirstAndLastName(
            result?.user?.displayName ??
              userDetails?.lastName ??
              '—'
          )?.lastName || "",
      };
      dispatch(
        login({
          payload,
          isTestEnv: constants.IS_TEST_ARRAY_URL,
        })
      ).then(() => {
        navigate("/dashboard", { replace: true });
      });
    } catch (error) {
      console.error("Apple login error: ", error);
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <Header />
      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <img src={"../../images/logo.png"} alt="Logo" />
          <div className={styles.loginInfo}>
            <button type="button" onClick={handleAppleLogin}>
              <img src={"./images/apple_icon.svg"} alt="Apple" title="Apple" />
              Apple Sign In
            </button>
            <button type="button" onClick={handleGoogleLogin}>
              <img
                src={"./images/google_icon.svg"}
                alt="Google"
                title="Google"
              />
              Google Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
